import dynamic from 'next/dynamic';

import { ScrollToLineSliceProps } from '@ui/slice-machine/slices/scroll-to-line-slice/ScrollToLineSlice';

const ScrollToLineSlice = dynamic<ScrollToLineSliceProps>(() =>
  import(
    '@ui/slice-machine/slices/scroll-to-line-slice/ScrollToLineSlice'
  ).then((val) => val.ScrollToLineSlice)
);

export default ScrollToLineSlice;
